<template>
  <div class="app-container">
    <div class="top_select">
      <div>
        <el-button
          icon="RefreshRight"
          size="small"
          class="mr-5"
          @click="getList()"
        ></el-button>
        <qzf-search-period
          v-model:period="listQuery.period"
          @success="getList"
        ></qzf-search-period>
        <el-input
          placeholder="请输入企业名称/编号"
          v-model="listQuery.name"
          class="w-160 ml-5"
          size="small"
          @keyup.enter="getList"
          clearable
        />
        <el-button
          class="mr-5"
          type="primary"
          size="small"
          @click="getList"
          icon="Search"
        >
          搜索
        </el-button>
        <search @success="getList" @cancel="cancel">
          <el-form
            style="margin: 10px 0px 0px 10px; width: 450px"
            label-width="100px"
            size="small"
          >
            <el-form-item label="纳税人类型：">
              <selecttaxtype v-model:type="listQuery.type"></selecttaxtype>
            </el-form-item>
            <el-form-item label="结账状态：">
              <el-radio-group v-model="listQuery.jzStatus">
                <el-radio label="">全部</el-radio>
                <el-radio label="2">未结账</el-radio>
                <el-radio label="1">已结账</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="质检状态：">
              <el-radio-group v-model="listQuery.zjStatus">
                <el-radio label="">全部</el-radio>
                <el-radio label="2">未质检</el-radio>
                <el-radio label="1">已质检</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="检测分值：">
              <el-input v-model="listQuery.beginGrade" class="w-140"></el-input>
              <span style="margin: 0 2px">-</span>
              <el-input v-model="listQuery.outGrade" class="w-140"></el-input>
            </el-form-item>
          </el-form>
        </search>
        <span class="ml-10">
          <i class="iconfont icon-tongzhi sbcg2"></i>优 &nbsp;&nbsp;
          <i class="iconfont icon-tongzhi sbz"></i>良 &nbsp;&nbsp;
          <i class="iconfont icon-tongzhi yczt"></i>中 &nbsp;&nbsp;
          <i class="iconfont icon-tongzhi sbyc2"></i>差
        </span>
      </div>
      <div>
        <el-button
          type="primary"
          size="small"
          @click="quailtyAll"
          icon="DocumentCopy"
          >批量质检
        </el-button>
        <!-- <el-button
          @click="daochu"
          type="primary"
          size="small"
          icon="FolderOpened"
        >
          导出
        </el-button> -->
      </div>
    </div>
    <div :style="{ height: contentStyleObj, paddingTop: '10px' }">
      <vxe-table
        v-loading="loading"
        :data="list"
        :scroll-y="{ enabled: true, gt: 0 }"
        height="auto"
        style="width: 100%"
        border
        stripe
        auto-resize
        size="mini"
        @checkbox-all="handleSelectionChangeAll"
        @checkbox-change="handleSelectionChange"
        :column-config="{ resizable: true }"
        @sort-change="sortChange"
      >
        <template #empty>
          <el-empty :image-size="150" description="没有数据"></el-empty>
        </template>
        <vxe-column
          align="center"
          field="sortColumn"
          type="checkbox"
          width="50"
        >
        </vxe-column>
        <vxe-column title="编号" width="70" align="center" sortable>
          <template #default="scope">
            <TableSortCell :row="scope.row" />
          </template>
        </vxe-column>
        <vxe-column title="公司名称">
          <template #default="scope">
            <TagNameCopy :row="scope.row" :showAuthIcon="false"></TagNameCopy>
          </template>
        </vxe-column>
        <vxe-column title="税局" align="center" width="100">
          <template #default="scope">
            <span>{{ $cityFilter(scope.row.district) }}</span>
          </template>
        </vxe-column>
        <vxe-column title="结账状态" align="center">
          <template #default="scope">
            <div class="item_icon">
              <i :class="settleStatusIcon(scope.row.settleStatus)"></i>
              <span>{{ settleStatusfilter(scope.row.settleStatus) }}</span>
            </div>
          </template>
        </vxe-column>
        <vxe-column title="检测结果" align="center">
          <template #default="scope">
            <div class="item_icon" v-if="scope.row.zjStatus == '1'">
              <i
                :class="qualityStatusIcon(scope.row.zjFs)"
                class="iconfont icon-tongzhi"
              ></i
              >{{ scope.row.zjFs }}
              <el-button link size="small" @click="check(scope.row)"
                >查看</el-button
              >
            </div>
            <div class="item_icon" v-else>
              <i class="iconfont icon-gantanhao"></i>未质检
            </div>
          </template>
        </vxe-column>
        <vxe-column title="操作" width="130">
          <template #default="scope">
            <el-button link size="small" @click="quailtyOne(scope.row)"
              >{{ scope.row.zjStatus == "1" ? "重新质检" : "质检" }}
            </el-button>
          </template>
        </vxe-column>
      </vxe-table>
    </div>
    <div class="pagination">
      <qzf-pagination
        v-show="total > 0"
        :total="total"
        v-model:page="listQuery.page"
        v-model:limit="listQuery.limit"
        @pagination="getList"
      />
    </div>
    <qualityInfo ref="qualityInfoRef" />
  </div>
</template>
<script setup>
import { ref, getCurrentInstance, onMounted } from "vue";
import { currentAccountPeriod } from "@/utils";
import TableSortCell from "@/components/table-cell-render/table-sort-cell/TableSortCell";
import TagNameCopy from "@/components/table-cell-render/company-name/TagNameCopy";
import qualityInfo from "./components/qualityInfo.vue";
import selecttaxtype from "@/components/Screening/selecttaxtype";
import { qualityTest, qualityCom } from "@/api/company";
const { proxy } = getCurrentInstance();
const listQuery = ref({
  period: currentAccountPeriod(),
  name: "",
  beginGrade: "",
  outGrade: "",
  jzStatus: "",
  page: 1,
  limit: 20,
});
const loading = ref(false);
const list = ref([]);
const total = ref(0);
const sels = ref([]);
const qualityInfoRef = ref();
const contentStyleObj = ref({});
onMounted(() => {
  contentStyleObj.value = proxy.$getHeight(210);
  getList();
});
const getList = () => {
  const { beginGrade, outGrade } = listQuery.value;
  if ((beginGrade && !outGrade) || (outGrade && !beginGrade)) {
    proxy.$qzfMessage("请输入分值区间", 1);
    return;
  }
  loading.value = true;
  qualityTest(listQuery.value).then((res) => {
    loading.value = false;
    if (res.data.msg == "success") {
      list.value = res.data.data.list ? res.data.data.list : [];
      total.value = res.data.data.total;
    }
  });
};
const handleSelectionChange = (data) => {
  sels.value = data.records;
};
const handleSelectionChangeAll = (data) => {
  sels.value = data.records;
};
//查看质检详情
const check = (row) => {
  qualityInfoRef.value.init(row, listQuery.value.period);
};
//批量质检
const quailtyAll = () => {
  if (sels.value.length == 0) {
    proxy.$qzfMessage("请选择需要质检的公司", 1);
  } else {
    let param = {
      comIds: sels.value.map((item) => item.id),
      period: listQuery.value.period,
    };
    qualityCom(param).then((res) => {
      if (res.data.msg == "success") {
        proxy.$qzfMessage("操作成功");
        getList();
      }
    });
  }
};
const quailtyOne = (row) => {
  let param = {
    comIds: [row.id],
    period: listQuery.value.period,
  };
  qualityCom(param).then((res) => {
    if (res.data.msg == "success") {
      proxy.$qzfMessage("操作成功");
      getList();
    }
  });
};
const settleStatusfilter = (type) => {
  if (type == "2") {
    return "结账失败";
  } else if (type == "4") {
    return "已结账";
  } else {
    return "未结账";
  }
};
const settleStatusIcon = (type) => {
  if (type == "2") {
    return "iconfont icon-cuowu";
  } else if (type == "4") {
    return "iconfont icon-duihao";
  } else {
    return "iconfont icon-gantanhao";
  }
};
const qualityStatusIcon = (flag) => {
  if (flag >= 90) {
    return "sbcg2";
  } else if (flag >= 80 && flag < 90) {
    return "sbz";
  } else if (flag >= 70 && flag < 80) {
    return "yczt";
  } else {
    return "sbyc2";
  }
};
const cancel = () => {
  const originLimit = listQuery.value.limit;
  listQuery.value = {
    period: currentAccountPeriod(),
    name: "",
    beginGrade: "",
    outGrade: "",
    jzStatus: "",
    page: 1,
    limit: originLimit,
  };
  getList();
};
</script>
<script>
export default {
  name: "bookQuality",
};
</script>

<style scoped lang="scss">
.top_select {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.iconfont {
  font-size: 13px;
  margin-right: 4px;
}
</style>
